<template>
  <Diagram :model="flowNodes" :editable="false" height="750"></Diagram>
</template>
<script>
import Diagram from "@admin/components/flow_diagram/Diagram";
import { mapActions, mapState } from "vuex";

export default {
  name: "Flow",
  async beforeMount() {
    await this.getFlow(this.screenerId);
  },
  components: {
    Diagram,
  },
  computed: {
    ...mapState("screenerFlow", ["flowNodes"]),
  },
  methods: {
    ...mapActions("screenerFlow", ["getFlow"]),
  },
  props: {
    screenerId: {
      type: String,
      required: true,
    },
  },
};
</script>
