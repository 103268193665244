<template>
  <v-card class="mx-auto my-12">
    <section-header :target="() => this.$refs.mediaChannelsContainer">
      <v-container>
        <v-row>
          <v-col cols="12" md="4"> Media Channels </v-col>
          <v-spacer></v-spacer>
          <v-btn
            class="mt-1"
            :to="{
              name: 'MediaChannelAdd',
              params: { screenerId: screenerId },
            }"
            color="primary"
            v-if="showAddMediaChannelButton"
          >
            <v-icon aria-label="Add Media Channel"> mdi-plus </v-icon>
            Add Media Channel
          </v-btn>
        </v-row>
      </v-container>
    </section-header>
    <v-card-text>
      <v-container fluid ref="mediaChannelsContainer">
        <v-data-table
          :headers="headers"
          :footer-props="footerProps"
          :items="mediaChannels"
          :loading="isLoading"
          :server-items-length="mediaChannelsCount"
          :options.sync="options"
        >
          <template v-slot:item.title="{ item }">
            <router-link
              class="view-link"
              :to="{ name: 'MediaChannelView', params: { id: item.id } }"
            >
              {{ item.attributes.title }}
            </router-link>
          </template>
          <template v-slot:item.type="{ item }">
            {{ item.attributes.type }}
          </template>
          <template v-slot:item.isActive="{ item }">
            <v-icon v-if="item.attributes.isActive" color="green"
              >mdi-check-circle</v-icon
            >
            <v-icon v-else color="red">mdi-cancel</v-icon>
          </template>
          <template v-slot:item.submissionsStarted="{ item }">
            {{ item.attributes.submissionsStarted || 0 }}
          </template>
          <template v-slot:item.submissionsCompleted="{ item }">
            {{ item.attributes.submissionsCompleted || 0 }}
          </template>
          <template v-slot:item.submissionsQualified="{ item }">
            {{ item.attributes.submissionsQualified || 0 }}
          </template>
          <template v-slot:item.code="{ item }">
            <copy-button
              small
              class="ma-2 white--text"
              @buttonClick="copyEmbedCode(item)"
            >
              COPY CODE
            </copy-button>
          </template>
          <template v-slot:no-data>
            <no-data-alert />
          </template>
        </v-data-table>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapState, mapGetters, mapActions } from "vuex";
import NoDataAlert from "@admin/components/NoDataAlert.vue";
import SectionHeader from "@admin/components/SectionHeader.vue";
import CopyButton from "../action_buttons/CopyButton.vue";
import _ from "lodash";

export default {
  name: "MediaChannelsTable",
  props: {
    isLoading: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    mediaChannels: {
      type: Array,
      required: true,
      default: () => [],
    },
    screenerId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Channel",
          value: "title",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "Live",
          value: "isActive",
        },
        {
          text: "# Started",
          value: "submissionsStarted",
          sortable: false,
        },
        {
          text: "# Completed",
          value: "submissionsCompleted",
          sortable: false,
        },
        {
          text: "# Qualified",
          value: "submissionsQualified",
          sortable: false,
        },
        {
          text: "Embed Code",
          value: "code",
          sortable: false,
          align: "center",
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
      },
    };
  },
  computed: {
    ...mapFields("mediaChannels", { snippet: "mediaChannel.snippet" }),
    ...mapGetters(["acceptRoles"]),
    ...mapState("mediaChannels", ["mediaChannelsCount"]),
    ...mapState(["footerProps"]),
    showAddMediaChannelButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
    pageAndSortData() {
      const data = (({ page, itemsPerPage, sortBy }) => ({
        page,
        limit: itemsPerPage,
        sortBy,
      }))(this.options);

      if (this.options.sortBy.length) {
        data.sortBy = [];
        for (let i = 0; i < this.options.sortBy.length; i++) {
          const sortField = this.options.sortBy[i];
          const desc = this.options.sortDesc[i] === true ? "-" : "";
          data.sortBy.push(desc + sortField);
        }
      }

      return data;
    },
  },
  watch: {
    pageAndSortData: {
      handler(currentValue, oldValue) {
        if (_.isEqual(currentValue, oldValue)) return;

        this.$emit("updateMediaChannels", currentValue);
      },
      deep: true,
    },
  },
  components: {
    NoDataAlert,
    SectionHeader,
    CopyButton,
  },
  methods: {
    ...mapActions("mediaChannels", ["getMediaChannel"]),
    async copyEmbedCode(selectedMediaChannel) {
      await this.getMediaChannel(selectedMediaChannel.id);
      navigator.clipboard.writeText(this.snippet);
    },
  },
};
</script>

<style scoped></style>
