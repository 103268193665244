<template>
  <div v-if="isLoading">
    <v-progress-linear indeterminate></v-progress-linear>
  </div>
  <div v-else>
    <v-card-text>
      <v-card class="mx-auto mb-12">
        <section-header
          :isCollapsable="false"
          :target="() => this.$refs.screenersDetailsContainer"
          >Details</section-header
        >
        <v-card-text>
          <v-container fluid ref="screenersDetailsContainer">
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Name:</dt>
                <dd v-text="title"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Description:</dt>
                <dd v-text="description"></dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Sponsor:</dt>
                <dd v-text="sponsorName"></dd>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Engagements:</dt>
                <dd v-if="screenerEngagements.length > 0">
                  <span
                    v-for="engagement in screenerEngagements"
                    :key="engagement.id"
                  >
                    {{ engagement.text }}
                    <br />
                  </span>
                </dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Additional Languages:</dt>
                <dd v-if="screenerAdditionalLanguagesList.length > 0">
                  <span
                    v-for="language in screenerAdditionalLanguagesList"
                    :key="language.id"
                  >
                    {{ language.text }}
                    <br />
                  </span>
                </dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Locked:</dt>
                <dd>
                  <v-icon :color="isLocked ? 'green' : 'red'">
                    {{ isLocked ? "mdi-check-circle" : "mdi-cancel" }}
                  </v-icon>
                  {{ isLocked ? "Yes" : "No" }}
                </dd>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="4">
                <dt>Active:</dt>
                <dd>
                  <v-icon :color="isActive ? 'green' : 'red'">
                    {{ isActive ? "mdi-check-circle" : "mdi-cancel" }}
                  </v-icon>
                  {{ isActive ? "Active" : "Inactive" }}
                </dd>
              </v-col>
              <v-col cols="12" sm="4">
                <dt>Test Mode:</dt>
                <dd>
                  <p
                    :style="
                      isTest
                        ? 'background: red; font-size: 1.5rem; font-weight: bold; padding: 10px;'
                        : ''
                    "
                  >
                    <v-icon :color="isTest ? 'yellow' : 'gray'">
                      {{
                        isTest ? "mdi-alert-circle-check-outline" : "mdi-cancel"
                      }}
                    </v-icon>
                    {{ isTest ? "Active" : "Inactive" }}
                  </p>
                </dd>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                v-if="screenerExported === null && showExportMetricsButton"
              >
                <v-btn @click="exportMetrics" color="primary">
                  Export Metrics
                  <v-icon right>mdi-download</v-icon>
                </v-btn>
              </v-col>
              <v-col
                v-if="screenerExported !== null && showExportMetricsButton"
                cols="12"
                sm="4"
              >
                <v-icon :color="screenerExported ? 'green' : 'red'">
                  {{ screenerExported ? "mdi-check-circle" : "mdi-cancel" }}
                </v-icon>
                Export has {{ screenerExported ? "succeeded" : "failed" }}.
              </v-col>

              <v-col cols="12" sm="4" class="d-none">
                <dt>Display Interests on Contact Form:</dt>
                <dd>
                  {{ isInterestVisible ? "Yes" : "No" }}
                </dd>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
      <v-card class="mx-auto mb-12">
        <section-header :target="() => this.$refs.surveysContainer"
          ><v-container>
            <v-row>
              <v-col> Surveys </v-col>
              <v-spacer></v-spacer>
              <v-btn
                :to="{
                  name: 'ScreenerSurveysEdit',
                  params: { id: this.screenerId },
                }"
                color="primary"
                class="mt-1"
                v-if="showAddSurveyButton"
              >
                Edit Surveys
              </v-btn>
            </v-row>
          </v-container></section-header
        >
        <v-card-text>
          <v-container fluid ref="surveysContainer">
            <screener-surveys-table
              :isLoading="surveysLoading"
              :screenerId="screenerId"
              :surveys="screenerSurveyList"
              :surveysCount="screenerSurveysCount"
            >
            </screener-surveys-table>
          </v-container>
        </v-card-text>
      </v-card>

      <media-channels-table
        :mediaChannels="mediaChannels"
        :is-loading="mediaChannelsLoading"
        :screener-id="screenerId"
        @updateMediaChannels="handleUpdateMediaChannels($event)"
      >
      </media-channels-table>

      <v-card class="mx-auto my-12 d-none">
        <section-header :target="() => this.$refs.contactFieldsContainer">
          <v-container>
            <v-row>
              <v-col cols="12" md="4"> Contact Fields </v-col>
              <v-spacer></v-spacer>
            </v-row>
          </v-container>
        </section-header>
        <v-card-text>
          <v-container fluid ref="contactFieldsContainer">
            <contact-fields-table
              :contactFields="contactFields"
              :contactFieldsCount="contactFieldsCount"
              :isLoading="isLoading"
              @updateContactFields="handleContactFieldsUpdate"
              :screener-id="screenerId"
            >
            </contact-fields-table>
          </v-container>
        </v-card-text>
      </v-card>

      <v-card class="mx-auto mb-12">
        <section-header :target="() => this.$refs.screenerFlow"
          ><v-container>
            <v-row>
              <v-col> Screener Flow </v-col>
            </v-row>
          </v-container>
        </section-header>
        <v-card-text>
          <v-container fluid ref="screenerFlow">
            <v-row>
              <v-col cols="12" ref="flowWrapper">
                <Flow :screener-id="screenerId"></Flow>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-card-text>
  </div>
</template>

<script>
import { mapFields } from "vuex-map-fields";
import { mapActions, mapGetters, mapState } from "vuex";
import SectionHeader from "@admin/components/SectionHeader.vue";
import { humanize } from "@utils/filters/stringHelpers";
import ScreenerSurveysTable from "@admin/components/screener_surveys/ScreenerSurveysTable.vue";
import Flow from "@admin/components/screeners/Flow.vue";
import MediaChannelsTable from "@admin/components/media_channels/MediaChannelsTable.vue";
import ContactFieldsTable from "@admin/components/contact_fields/Table";
import Vue from "vue";

export default {
  name: "ScreenerView",
  components: {
    ContactFieldsTable,
    MediaChannelsTable,
    Flow,
    SectionHeader,
    ScreenerSurveysTable,
  },
  async beforeMount() {
    this.mediaChannelsLoading = true;
    // Load Screener details
    this.isLoading = true;
    await this.getScreener({
      id: this.screenerId,
    });
    await this.getScreenerSurveys({
      screenerId: this.screenerId,
    });
    await this.getMediaChannels({ screenerId: this.screenerId });
    this.mediaChannelsLoading = false;
    this.isLoading = false;

    this.setTitleObject(this.title);
    this.setSponsorBreadcrumb({
      id: this.sponsorId,
      name: this.sponsorName,
    });
    this.setBreadcrumbs();
  },
  computed: {
    ...mapFields("screeners", {
      description: "screener.description",
      isActive: "screener.isActive",
      isLocked: "screener.isLocked",
      isTest: "screener.isTest",
      sponsorId: "screener.sponsorId",
      sponsorName: "screener.sponsorName",
      title: "screener.title",
      type: "screener.type",
      userEmail: "screener.userEmail",
      isInterestVisible: "screener.isInterestVisible",
      screenerEngagements: "screenerEngagements",
    }),

    ...mapState("contactFields", ["contactFields", "contactFieldsCount"]),
    ...mapState("mediaChannels", ["mediaChannels"]),

    ...mapState("screenerSurveys", [
      "screenerSurveys",
      "screenerSurveyList",
      "screenerSurveysCount",
    ]),
    ...mapState("surveysNew", ["surveys"]),
    ...mapGetters(["acceptRoles"]),
    ...mapGetters("screeners", ["screenerAdditionalLanguagesList"]),
    showDeleteScreenerButton() {
      return this.acceptRoles(["Admin", "Super User"]);
    },
    showEditScreenerButton() {
      return this.acceptRoles(["Admin", "Super User"]);
    },
    showAddSurveyButton() {
      return this.acceptRoles(["Manager", "Admin", "Editor", "Super User"]);
    },
    showExportMetricsButton() {
      return this.acceptRoles(["Super User"]);
    },
  },
  data() {
    return {
      isLoading: false,
      screenerId: this.$route.params.screenerId,
      surveysLoading: false,
      mediaChannelsLoading: false,
      screenerExported: null,
    };
  },
  destroyed() {
    this.clearScreener();
    this.clearTitleObject();
  },
  filters: { humanize },
  methods: {
    ...mapActions("screeners", [
      "clearScreener",
      "deleteScreener",
      "getScreener",
    ]),
    ...mapActions("screenerSurveys", ["getScreenerSurveys"]),
    ...mapActions("surveysNew", ["getSurveys"]),
    ...mapActions("navigationBar", [
      "clearTitleObject",
      "setActionButtons",
      "setBreadcrumbs",
      "setSponsorBreadcrumb",
      "setTitleObject",
    ]),
    ...mapActions("mediaChannels", ["getMediaChannels", "clearMediaChannels"]),
    ...mapActions("contactFields", ["getContactFields", "clearContactFields"]),
    async handleUpdateMediaChannels(options) {
      this.mediaChannelsLoading = true;
      await this.getMediaChannels({
        screenerId: this.screenerId,
        ...options,
      });
      this.mediaChannelsLoading = false;
    },
    async handleContactFieldsUpdate(options) {
      this.isLoading = true;
      await this.getContactFields(options);
      this.isLoading = false;
    },
    exportMetrics() {
      Vue.axios
        .get("/api/export-survey-metrics/" + this.screenerId)
        .then((response) => {
          this.screenerExported = response.data;
        })
        .catch(() => {
          this.screenerExported = false;
        });
      setTimeout(() => {
        this.screenerExported = null;
      }, 3000);
    },
  },
  async mounted() {
    const actionButtons = [];
    if (this.showDeleteScreenerButton) {
      actionButtons.push({
        attributes: {
          onConfirm: () => {
            const sponsorId = this.sponsorId;
            this.deleteScreener(this.screenerId).then(() => {
              this.$router.push({
                name: "SponsorsView",
                params: { id: sponsorId },
              });
            });
            this.isLoading = false;
          },
        },
        component: "DeleteButton",
        index: 0,
      });
    }
    if (this.showEditScreenerButton) {
      actionButtons.push({
        attributes: {
          to: "/screeners/edit/:id".replace(":id", this.screenerId),
        },
        component: "EditButton",
        index: 1,
      });
    }
    this.setActionButtons(actionButtons);
    if (this.contactFields === undefined || this.contactFields.length === 0) {
      this.isLoading = true;
      await this.getContactFields({
        screenerId: this.screenerId,
      });
      this.isLoading = false;
    }
  },
};
</script>

<style scoped>
dt {
  font-weight: bold;
  font-size: 1.1rem;
}
</style>
