<template>
  <div>
    <v-data-table
      :headers="headers"
      :footer-props="footerProps"
      :items="surveys"
      :loading="isLoading"
      :server-items-length="surveysCount"
      :options.sync="options"
    >
      <template v-slot:item.title="{ item }">
        <router-link
          class="view-link"
          :to="{
            name: 'ScreenerSurveysEdit',
            params: { id: screenerId },
          }"
        >
          {{ item.title }}
        </router-link>
      </template>
      <template v-slot:item.modified="{ item }">
        {{ formatDatetime(item.modified) }}
      </template>
      <template v-slot:no-data>
        <no-data-alert />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NoDataAlert from "@admin/components/NoDataAlert.vue";
import _ from "lodash";

export default {
  name: "ScreenerSurveysTable",
  props: {
    // Allow for the user of this component to indicate loading since data used in this component
    // must be loaded externally (e.g. which surveys should be shown should be loaded externally
    // based on what is appropriate for the current scope). We want the external context to load data,
    //  determine when the necessary data is loaded, and give this component permission to show it
    isLoading: {
      required: false,
      type: Boolean,
      default: () => false,
    },
    screenerId: {
      required: true,
      type: String,
    },
    surveys: {
      required: true,
      type: Array,
      default: () => [],
    },
    surveysCount: {
      required: true,
      type: Number,
      default: () => 0,
    },
  },
  data() {
    return {
      headers: [
        {
          text: "Name",
          value: "title",
          sortable: false,
        },
        {
          text: "Page",
          value: "page",
          sortable: false,
        },
        {
          text: "Question",
          value: "question",
          sortable: false,
        },
        {
          text: "Last Modified Date",
          value: "modified",
          sortable: false,
        },
      ],
      options: {
        page: 1,
        itemsPerPage: 20,
        sortBy: [],
        sortDesc: [],
      },
      associationSnackbar: false,
      associationStatus: "associated",
      errorSnackbar: false,
    };
  },
  computed: {
    ...mapState(["footerProps"]),

    pageAndSortData() {
      const data = (({ page, itemsPerPage, sortBy }) => ({
        page,
        limit: itemsPerPage,
        sortBy,
      }))(this.options);

      if (this.options.sortBy.length) {
        data.sortBy = [];
        for (let i = 0; i < this.options.sortBy.length; i++) {
          let sortField = this.options.sortBy[i];
          const desc = this.options.sortDesc[i] === true ? "-" : "";
          data.sortBy.push(desc + sortField);
        }
      }

      return data;
    },
  },
  watch: {
    pageAndSortData: {
      handler(currentValue, oldValue) {
        if (_.isEqual(currentValue, oldValue)) return;

        this.$emit("updateSurveys", currentValue);
      },
      deep: true,
    },
  },
  components: {
    NoDataAlert,
  },
};
</script>
